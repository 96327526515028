<template>
  <b-card
    v-if="allGoals"
    no-body
    class="ml-2"
  >
    <div class="d-flex">
      <h4 class="mb-2 mr-1">
        {{ $t('Goals') }}
      </h4>
      <feather-icon
        v-if="canEditContent"
        icon="EditIcon"
        size="16"
        class="text-muted cursor-pointer"
        @click="$router.push({ name: 'goals-list-id', params: { id: contentId }})"
      />
    </div>
    <b-table
      :items="allGoals"
      responsive
      :fields="goalsTableColumns"
      :empty-text="$t('NO_MATCHING_RECORDS_FOUND')"
      primary-key="id"
      class="mb-0"
    >

      <!-- Description -->
      <template #cell(Description)="data">
        <div class="d-flex align-items-center">
          <span class="font-weight-bolder mr-1"> {{ strSubstring(data.item.description,15) }}</span>
        </div>
      </template>

      <!-- Column:Task & Subtask-->
      <template #cell(Source)="data">
        <div class="d-flex align-items-center">
          <span class="font-weight-bolder mr-1">{{ strSubstring(data.item.task.name,10) }} ({{ strSubstring(data.item.subtask.name,10) }})</span>
        </div>
      </template>

      <!-- Column:Goal Type -->
      <template #cell(GoalType)="data">
        <div class="d-flex align-items-center">
          <span class="font-weight-bolder mr-1">{{ data.item.goalType.name }}</span>
        </div>
      </template>

      <!-- Column:Goal Period -->
      <template #cell(GoalPeriod)="data">
        <div class="d-flex align-items-center">
          <span class="font-weight-bolder mr-1">{{ data.item.goalPeriod.name }}</span>
        </div>
      </template>
      <!-- Column:Goal Subject -->
      <template #cell(GoalSubject)="data">
        <div class="d-flex align-items-center">
          <span class="font-weight-bolder mr-1">{{ data.item.goalSubject.name }}</span>
        </div>
      </template>
      <!-- Column:Value -->
      <template #cell(Value)="data">
        <div class="d-flex align-items-center">
          <span class="font-weight-bolder mr-1">{{ data.item.value }}</span>
        </div>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import { BCard, BTable } from 'bootstrap-vue'
import { mixinList } from '@/constants/mixinValidations'

export default {
  components: {
    BCard,
    BTable,
  },
  mixins: [mixinList],
  props: {
    allGoals: {
      type: Array,
      default: () => [],
      required: true,
    },
    canEditContent: {
      type: Boolean,
      default: () => true,
    },
    contentId: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  setup() {
    // Table Handlers
    const goalsTableColumns = [
      { key: 'Description', sortable: false },
      { key: 'Source', sortable: false },
      { key: 'GoalType', sortable: false },
      { key: 'GoalPeriod', sortable: false },
      { key: 'GoalSubject', sortable: false },
      { key: 'Value', sortable: false },
    ]

    return {
      goalsTableColumns,
    }
  },

}

</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
