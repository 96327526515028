<template>
  <b-card
    v-if="data"
    no-body
  >
    <b-row>
      <b-col
        md="12"
        lg="12"
      >
        <div class="d-sm-flex justify-content-between align-items-center mt-1 ml-1">
          <span class="icon-text">
            <span
              aria-label="Robot"
            > 🤖 💬</span>
          </span>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        md="12"
        lg="12"
      >
        <div class="d-sm-flex justify-content-between align-items-center mb-1 mt-1 ml-1">
          <span
            class="text-content"
            v-html="data"
          />
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
  },
  props: {
    data: {
      type: String,
      default: '',
    },
  },
}
</script>
<style scoped>
.icon-text {
  display: flex;
  align-items: center; /* Ensures the icon and the text are aligned */
  font-size: 1.25rem; /* Adjust size as needed */
  margin-right: 0.5rem; /* Adds spacing between the icon and the text */
}

.text-content {
  flex-grow: 1; /* Allows the text span to take up any remaining space */
}
</style>
