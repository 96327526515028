<template>
  <b-card
    no-body
    class="card-developer-meetup"
  >
    <b-card-body>
      <div class="w-100 mb-1 text-center">
        <p
          class="mb-1"
          v-html="$t('DOWNLOAD_YOUR_APP_HERE')"
        />

        <b-link
          target="_blank"
          :href="appUrl.android"
        >
          <b-img
            fluid
            :src="require('@/assets/images/pages/google_badge.svg')"
            width="150px"
            height="60px"
            class="center-img"
            alt="landing page"
          />
        </b-link>

        <b-link
          target="_blank"
          :href="appUrl.ios"
        >
          <b-img
            fluid
            :src="require('@/assets/images/pages/ios_badge.svg')"
            width="136px"
            height="60px"
            alt="landing page"
          />
        </b-link>
        <div v-if="content.recordStatus!==0">
          <p class="mt-1">
            {{ $t('SCAN_THE_QR_CODE') }}
          </p>
          <qrcode-vue

            :value="invitationLink"
            size="125"
            level="L"
            foreground="#EE6E25"
            class="mt-2 mb-1"
          />
        </div>
      </div>
      <!-- metting header -->
      <div class="meetup-header d-flex align-items-center">
        <div class="meetup-day">
          <feather-icon
            icon="AwardIcon"
            size="34"
            class="mr-50 text-primary"
          />
        </div>
        <div class="my-auto">
          <b-card-title class="mb-25">
            {{ content.name }}
            <small class="text-muted">
              <b-badge
                class="ml-1 small"
                :variant="`${mapFrequencyToVariant(content.contentFrequency.name)}`"
              >
                {{ content.contentType.name }}
              </b-badge>
              <b-badge
                class="ml-1 small"
                :variant="`${mapFrequencyToVariant(content.contentFrequency.name)}`"
              >
                {{ content.contentFrequency.name }}
              </b-badge>
              <b-badge
                class="ml-1 small"
                variant="light-info"
              >
                {{ content.contentStatus.name }}
              </b-badge>
            </small>
            <feather-icon
              v-if="canEditContent"
              icon="EditIcon"
              size="16"
              class="text-muted cursor-pointer ml-1"
              @click="$router.push({ name: 'content-configuration-id', params: { id: content.id }})"
            />
            <b-button
              v-if="content.recordStatus===0"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="shadow ml-1"
              @click="onPublish(content)"
            >
              <span>{{ $t('PUBLISH_GAME') }} </span>
            </b-button>
          </b-card-title>
          <b-card-text class="mb-0">
            <p v-html="content.description" />
          </b-card-text>
        </div>
      </div>
      <!-- start date -->
      <b-media
        no-body
      >
        <b-media-aside class="mr-1">
          <b-avatar
            rounded
            variant="light-primary"
            size="34"
          >
            <feather-icon
              icon="CalendarIcon"
              size="18"
            />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="mt-0 pt-50">
            {{ formatDateTime(content.start) }}
          </h6>
        </b-media-body>
      </b-media>
      <b-media
        no-body
      >
        <!-- end date -->
        <b-media-aside class="mr-1">
          <b-avatar
            rounded
            variant="light-primary"
            size="34"
          >
            <feather-icon
              icon="CalendarIcon"
              size="18"
            />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="mt-0 pt-50">
            {{ formatDateTime(content.end) }}
          </h6>
        </b-media-body>
      </b-media>
      <div v-if="content.recordStatus!==0">
        <b-card-text class="mt-2">
          <h5>{{ $t('AFTER_PUBLISHING_THE_GAME') }}</h5>
          <p class="ml-3 mt-1">
            {{ $t('MESSAGE.AFTER_PUBLISH') }}
          </p>
          <p class="ml-3">

            <ul>
              <!--Players invitation link: -->
              <b-media
                v-if="content.recordStatus!==0 && content.visibility!==1"
                no-body
              >

                <b-media-aside

                  class="mr-1"
                >
                  <b-avatar
                    rounded
                    variant="light-success"
                    size="34"
                  >
                    <feather-icon
                      class="cursor-pointer"
                      icon="Link2Icon"
                      size="18"
                      @click="copyLink(invitationLink)"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="mt-0 pt-50">
                    {{ $t('MESSAGE.PLAYERS_INVITATION_TEXT') }}
                  </h6>
                </b-media-body>
              </b-media>
              <b-media
                no-body
              >
                <b-media-aside class="mr-1">
                  <b-avatar
                    rounded
                    variant="light-primary"
                    size="34"
                  >
                    <feather-icon
                      icon="ActivityIcon"
                      size="18"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="mt-0 pt-50">
                    {{ $t('ADD_TASK') }}
                  </h6>
                </b-media-body>
              </b-media>
              <b-media
                no-body
              >
                <b-media-aside class="mr-1">
                  <b-avatar
                    rounded
                    variant="light-primary"
                    size="34"
                  >
                    <feather-icon
                      icon="ClockIcon"
                      size="18"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="mt-0 pt-50">
                    {{ $t('ADD_KPI') }}
                  </h6>
                </b-media-body>
              </b-media>
              <b-media
                no-body
              >
                <b-media-aside class="mr-1">
                  <b-avatar
                    rounded
                    variant="light-primary"
                    size="34"
                  >
                    <feather-icon
                      icon="TargetIcon"
                      size="18"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="mt-0 pt-50">
                    {{ $t('ADD_GOAL') }}
                  </h6>
                </b-media-body>
              </b-media>
              <b-media
                no-body
              >
                <b-media-aside class="mr-1">
                  <b-avatar
                    rounded
                    variant="light-primary"
                    size="34"
                  >
                    <feather-icon
                      icon="GiftIcon"
                      size="18"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="mt-0 pt-50">
                    {{ $t('ADD_CHARITY') }}
                  </h6>
                </b-media-body>
              </b-media>
              <b-media
                v-if="allSetReports"
                no-body
              >
                <b-media-aside class="mr-1">
                  <b-avatar
                    rounded
                    variant="light-success"
                    size="34"
                  >
                    <feather-icon
                      icon="BarChart2Icon"
                      class="text-success  cursor-pointer"
                      size="30"
                      @click="downloadSetReport()"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="mt-0 pt-50">
                    {{ $t('DOWNLOAD_REPORT') }}
                  </h6>
                </b-media-body>
              </b-media>
            </ul>
          </p>
        </b-card-text>
      </div>
      <div v-if="content.recordStatus===0">
        <b-card-text class="mt-2">
          <p>{{ $t('MESSAGE.GAME_READY') }}</p>
          <p class="ml-3">
            {{ $t('MANDATORY') }}
          </p>
          <p class="ml-3">
            <ul>
              <li>{{ $t('MESSAGE.HIT_PUBLISH') }}</li>
              <li>{{ $t('MESSAGE.ONCE_PUBLISH') }} </li>
            </ul>
          </p>
          <p class="ml-3">
            {{ $t('MESSAGE.FINE_TUNE_HABIT_BEHAVIOUR') }}
          </p>
          <p class="ml-3">
            <ul>
              <b-media
                no-body
              >
                <b-media-aside class="mr-1">
                  <b-avatar
                    rounded
                    variant="light-primary"
                    size="34"
                  >
                    <feather-icon
                      icon="ActivityIcon"
                      size="18"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="mt-0 pt-50">
                    {{ $t('ADD_TASK') }}
                  </h6>
                </b-media-body>
              </b-media>
              <b-media
                no-body
              >
                <b-media-aside class="mr-1">
                  <b-avatar
                    rounded
                    variant="light-primary"
                    size="34"
                  >
                    <feather-icon
                      icon="ClockIcon"
                      size="18"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="mt-0 pt-50">
                    {{ $t('ADD_KPI') }}
                  </h6>
                </b-media-body>
              </b-media>
              <b-media
                no-body
              >
                <b-media-aside class="mr-1">
                  <b-avatar
                    rounded
                    variant="light-primary"
                    size="34"
                  >
                    <feather-icon
                      icon="TargetIcon"
                      size="18"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="mt-0 pt-50">
                    {{ $t('ADD_GOAL') }}
                  </h6>
                </b-media-body>
              </b-media>
              <b-media
                no-body
              >
                <b-media-aside class="mr-1">
                  <b-avatar
                    rounded
                    variant="light-primary"
                    size="34"
                  >
                    <feather-icon
                      icon="GiftIcon"
                      size="18"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="mt-0 pt-50">
                    {{ $t('ADD_CHARITY') }}
                  </h6>
                </b-media-body>
              </b-media>
            </ul>
          </p>
        </b-card-text>
        <!--Landing url: -->
        <b-media
          v-if="content.recordStatus!==0"
          no-body
        >

          <b-media-aside

            class="mr-1"
          >
            <b-avatar
              rounded
              variant="light-primary"
              size="34"
            >
              <feather-icon
                class="cursor-pointer"
                icon="Link2Icon"
                size="18"
                @click="copyLink(landingLink)"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <b-link
              class="mt-0 pt-50"
              target="_blank"
              :href="landingLink"
            > {{ $t('LANDING_LINK') }} </b-link>
          </b-media-body>
        </b-media>

        <!--Players invitation link: -->
        <b-media
          v-if="content.recordStatus!==0"
          no-body
        >
          <b-media-aside
            class="mr-1"
          >
            <b-avatar
              rounded
              variant="light-primary"
              size="34"
            >
              <feather-icon
                class="cursor-pointer"
                icon="Link2Icon"
                size="18"
                @click="copyLink(invitationLink)"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <b-link
              class="mt-0 pt-50"
              target="_blank"
              :href="invitationLink"
            > {{ $t('PLAYERS_INVITATION_LINK') }} </b-link>
          </b-media-body>
        </b-media>

        <!--Invitation url: -->
        <b-media
          v-if="content.recordStatus!==0"
          no-body
        >
          <b-media-aside
            class="mr-1"
          >
            <b-avatar
              rounded
              variant="light-primary"
              size="34"
            >
              <feather-icon
                class="cursor-pointer"
                icon="Link2Icon"
                size="18"
                @click="copyLink(leaderboardLink)"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <b-link
              class="mt-0 pt-50"
              target="_blank"
              :href="landingLink"
            > {{ $t('LEADER_BOARD') }} </b-link>
          </b-media-body>
        </b-media>
        <hr class="invoice-spacing mt-3">
        <!-- avatar group -->
        <b-avatar-group
          class="mt-2 pt-50"
          size="33"
        >
          <h6 class="align-self-center cursor-pointer ml-1 mb-0">
            {{ content.usersCount }} {{ $t('PLAYERS') }}  -  {{ content.groupCount }} {{ $t('TEAMS') }}
          </h6>
          <feather-icon
            v-if="canEditContent"
            icon="EditIcon"
            size="16"
            class="text-muted cursor-pointer ml-1"
            @click="$router.push({ name: 'team-list-id', params: { id: encrypt_id }})"
          />
        </b-avatar-group>
      </div></b-card-body>
    <b-row>
      <b-col
        v-for="(team,index) in allTeams"
        :key="index"
        lg="4"
        md="6"
      >
        <b-card class="card-app-design">

          <!-- design group -->
          <div class="design-group">
            <h6 class="section-label">
              {{ mapTeamRoleToVariant(team.teamCoach).description_2 }}
            </h6>
            <b-badge
              :key="index"
              :variant="changeVariant(index)"
              class="mr-1"
            >
              {{ team.name }}
            </b-badge>
          </div>
          <div class="design-group">
            <div>
              <h6 class="section-label">
                {{ mapTeamRoleToVariant(team.teamCoach).description_1 }}
              </h6>
            </div>
          </div>
          <b-badge
            v-for="(teamUser,userIndex) in team.teamUsers"
            :key="userIndex"
            :variant="changeVariant(index)"
            class="mr-1"
          >
            {{ teamUser.user.firstName + ' ' + teamUser.user.lastName.charAt(0) }}
          </b-badge>
        </b-card>
      </b-col>
    </b-row>
    <hr class="invoice-spacing mb-3">
    <b-row>
      <b-col
        cols="12"
        md="4"
      >
        <content-time-line
          :content-id="Number(content.id)"
          :settimes="allSettimes"
          :can-edit-content="canEditContent"
        />
      </b-col>
      <b-col
        cols="12"
        md="8"
      >
        <content-task-list
          :content-id="Number(content.id)"
          :all-content-tasks="allContentTasks"
          :can-edit-content="canEditContent"
        />
      </b-col>
    </b-row>
    <hr class="invoice-spacing  mb-3">
    <b-row>
      <b-col
        cols="12"
        md="12"
      >
        <content-goals-list
          :content-id="Number(content.id)"
          :all-goals="allGoals"
          :can-edit-content="canEditContent"
        />
      </b-col>
    </b-row>
    <hr class="invoice-spacing mb-3">
    <b-row>
      <b-col
        cols="12"
        md="12"
      >
        <content-charity
          :content-id="Number(content.id)"
          :can-edit-content="canEditContent"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardText, BCardTitle, BMedia,
  BMediaAside, BLink, BImg,
  BAvatar, BAvatarGroup, VBTooltip,
  BMediaBody, BRow, BCol, BBadge, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/named
import { useToast } from 'vue-toastification/composition'
import { useClipboard } from '@vueuse/core'
// eslint-disable-next-line import/no-extraneous-dependencies
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions, mapState } from 'vuex'
import QrcodeVue from 'qrcode.vue'
import { mixinList } from '@/constants/mixinValidations'
// eslint-disable-next-line import/extensions, import/no-unresolved
import { mixinDate } from '@/constants/mixinDate'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import constants from '@/constants/static.json'
import mixinAlert from '@/constants/mixinAlert'
import useJwt from '@/auth/jwt/useJwt'
import {
  mapFrequencyToVariant,
  mapTeamRoleToVariant,
  isContentEditableOrDeletable,
} from '@/constants/utils'
import ContentTimeLine from './ContentTimeLine.vue'
import ContentTaskList from './ContentTaskList.vue'
import ContentGoalsList from './ContentGoalsList.vue'
import ContentCharity from './ContentCharity.vue'
import FileView from '../content-configuration/FileView.vue'

/* eslint-disable global-require */
export default {
  components: {
    BCard,
    BCardBody,
    BCardText,
    BCardTitle,
    BMedia,
    BMediaAside,
    BAvatar,
    BAvatarGroup,
    BMediaBody,
    BRow,
    BCol,
    BBadge,
    BButton,
    BLink,
    BImg,
    QrcodeVue,
    ContentTimeLine,
    ContentTaskList,
    ContentGoalsList,
    ContentCharity,
    FileView,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [mixinList, mixinDate],
  data() {
    return {
      invitationLink: '',
      leaderboardLink: '',
      landingLink: '',
      analyzeData: '',
      canEditContent: false,
      userData: useJwt.getUser() || {},
      allTeamUsers: [],
      content: {
        id: 0,
        externalReference: '',
        sourceContentId: null,
        contentStatusId: 0,
        contentFrequencyId: 0,
        contentTypeId: 0,
        projectId: 0,
        companyId: 0,
        length: 0,
        name: '',
        start: '',
        end: null,
        tag: '',
        language: '',
        chatEnabled: false,
        visibility: false,
        template: false,
        analyzeIndividual: false,
        description: constants.CONTENT_DESCRIPTION,
        contentFrequency: {},
        contentStatus: {},
        contentType: {},
      },
      appUrl: {
        android: constants.APP_URL.ANDROID,
        ios: constants.APP_URL.IOS,
        webGl: constants.APP_URL.WEB_GL,
      },
    }
  },
  computed: {
    ...mapState('report', ['allSetReports']),
    ...mapState('task', ['allContentTasks']),
    ...mapState('settime', ['allSettimes']),
    ...mapState('goal', ['allGoals']),
    ...mapState('kpi', ['allKpis']),
    ...mapState('team', ['allTeams']),
    ...mapState('goal', ['allGoals']),
  },
  created() {
    if (router.currentRoute.params.id) {
      this.content.id = router.currentRoute.params.id
      this.fetchContent(this.content.id)
    }
  },
  methods: {
    ...mapActions('settime', ['fetchSetReport']),
    ...mapActions('report', ['fetchReportByID']),
    ...mapActions('task', ['fetchContentTaskByID']),
    ...mapActions('settime', ['fetchAllSettimes']),
    ...mapActions('goal', ['fetchAllGoals']),
    ...mapActions('team', [
      'fetchAllTeams']),
    ...mapActions('content', ['updateContent', 'fetchContentByID']),
    async fetchContent(id) {
      const response = await this.fetchContentByID(id)
      if (response && response.data) {
        this.content = response.data
        this.canEditContent = isContentEditableOrDeletable(this.content)
        useJwt.setContent(this.content)
        this.fetchContentTaskByID(this.content.id)
        this.fetchAllSettimes(this.content.id)
        this.fetchAllGoals(this.content.id)
        this.fetchTeams()
      }

      // this.fetchReportByID({
      //   params: `log_type=SET_REPORT&log_object=GAME&log_object_id=${this.competition.ID}`,
      // })
      // this.fetchAnalyzeByID(this.competition.ID)
    },
    async fetchTeams() {
      try {
        if (this.content.id) {
          await this.fetchAllTeams(this.content.id)
          this.transformTeamData()
        }
      } catch {
        this.showErrorMessage()
      }
    },
    async fetchAnalyzeByID(id) {
      const payload = {
        id,
        params: '?setid=0&groupid=0',
      }
      this.fetchGroupResultByID(payload).then(response => {
        if (response.data) {
          this.analyzeData = response.data.feedback.replace(/\n/g, '<br>')
        }
      })
    },
    transformTeamData() {
      this.allTeamUsers = []
      this.allTeams.forEach(team => {
        team.teamUsers.forEach(teamUser => {
          this.allTeamUsers.push({
            teamId: teamUser.teamId,
            teamCoach: team.teamCoach,
            userId: teamUser.userId,
            teamName: team.name,
            firstName: teamUser.user.firstName,
            lastName: teamUser.user.lastName,
            email: teamUser.user.email,
          })
        })
      })
    },
    async downloadSetReport() {
      await this.fetchSetReport(`${this.competition.ID}.xlsx`).then(response => {
        if (response) {
          this.successMessage(this.$i18n.t('MESSAGE.REPORT_DOWNLOAD'))
        }
      })
    },
    setLink(data) {
      this.invitationLink = `${process.env.VUE_APP_HOST}onboarding-user/${data.encrypt_id}`
      this.leaderboardLink = `${process.env.VUE_APP_HOST}leaderboard/${data.encrypt_id}`
      this.landingLink = `${process.env.VUE_APP_HOST}${data.encrypt_id}`
    },
    onPublish(data) {
      this.boxTwo = ''
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('MESSAGE.PUBLISH_CONFIRMATION')} ${data.name}  game ?`, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            // eslint-disable-next-line no-param-reassign
            data.record_status = 1
            this.updateGame(data).then(response => {
              if (response) {
                this.successMessage(this.$i18n.t('MESSAGE.GAME_PUBLISHED'))
              }
            })
          }
        })
    },
  },
  setup() {
    const toast = useToast()
    const { copy } = useClipboard()

    const copyLink = link => {
      copy(link)
      toast({
        component: ToastificationContent,
        props: {
          title: 'The link is copied',
          icon: 'CopyIcon',
          variant: 'success',
        },
      })
    }

    const {
      successMessage,
    } = mixinAlert()
    return {
      copyLink,
      mapFrequencyToVariant,
      mapTeamRoleToVariant,
      successMessage,
    }
  },

}
</script>
